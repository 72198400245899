import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants} from 'src/app/shared/constants.shared';
import {Country} from 'src/app/interfaces/country.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../utils/form.utils';
import {SessionService} from '../../services/session.service';
import {Router, ActivatedRoute} from '@angular/router';
import ModelUtils from 'src/app/utils/model.utils';
import {TranslateService} from '@ngx-translate/core';
import {IdentificationService as IdentificationService} from '../../services/identification.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CountryDialogComponent} from 'src/app/shared/country-dialog/country-dialog.component';
import {HttpClient} from '@angular/common/http';
import {TemporalPhone} from "../../interfaces/responses/temporal-phone-res.interface";
import jwtDecode from "jwt-decode";
import { DecodedToken } from 'src/app/interfaces/responses/decodedToken';

@Component({
  selector: 'phone-page',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  accessTokenLoaded: boolean = false; 

  _country: Country | undefined = undefined;

  _countries: Country[] = [];

  _valid: boolean = false;
  _loading: boolean = false;

  privacyPolicy!: SafeHtml;
  termsAndConditions!: SafeHtml;

  tcVersion?: number;
  ppVersion?: number;

  tcUrl?: string;
  ppUrl?: string;

  //userLanguage
  options: { value: string, label: string }[] = [];
  languageCode:string |undefined;
  datos: { [key: string]: string } = {};

  userPhoneNumber: string | null = null;
  userIdentityDocumentType: string | null = null;
  userIdentityDocument: string | null = null;


  form: FormGroup = this.formBuilder.group({
    countryId: [, [Validators.required]],
    phone: ["", [Validators.required]],
    tcChecked: [false, Validators.requiredTrue],
    ppChecked: [false, Validators.requiredTrue]
  });

  @ViewChild("phoneInput", { static: false }) phoneInput: any;

  get logo() { return this.session.logo; }

  set loading(value: boolean) {
    this._loading = value;
    FormUtils.enableForm(this.form, !value);
  }

  set country(country: Country | undefined) {
    this._country = country;
    this.form.controls["countryId"].setValue(country ? country.id : undefined);
  }

  get country() { return this._country; }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private session: SessionService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private identification: IdentificationService,
    private http: HttpClient,
  ) {
    this.translate.onLangChange.subscribe(() => this.refreshLanguage());
  }

  ngOnInit() {
    this.fetchData();
    this.initializeLegalTexts();
    this.loadSessionLanguage();
    this.loadInputData();
    this.form.valueChanges.subscribe(_ => this.validate());

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.phoneInput) this.phoneInput.nativeElement.focus();
    });
  }

  fetchData() {
    this.identification.masters().subscribe({
      next: res => {
        this._countries = res.countries;
        const defaultCountry = ModelUtils.getDefaultCountry(res.countries);
        if (defaultCountry) this.country = defaultCountry;
      }
    });
  }

  loadInputData() {
    const operationId =this.route.snapshot.queryParamMap.get('operationId') || '';

    const phoneFromService = this.identification.userPhoneNumber;
    if (phoneFromService) {
      this.form.controls['phone'].setValue(phoneFromService);
      return;
    }

    this.identification.initialize(operationId).subscribe({
      next: () => {
      try {
        const token = localStorage.getItem("accessToken");
        if(token){
        const decodedToken = jwtDecode(token) as DecodedToken;
        
        const phone = decodedToken.phoneNumber;

        this.form.controls['phone'].setValue(phone);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    },
    error: (error) => {
      console.error("Initialization error:", error);
    },
    complete: () => {
      console.log("Initialization complete");
      }
    });
  }

  initializeLegalTexts() {

    this.identification.masters().subscribe(res => {

      this.ppUrl = res.privacyPolicy.url;
      this.tcUrl = res.termsAndConditions.url;

      this.ppVersion = res.privacyPolicy.version;
      this.tcVersion = res.termsAndConditions.version;

      const privacyText = this.translate.instant("title.accept_policy",{'url': this.ppUrl});
      const termsText = this.translate.instant("title.accept_terms",{'url': this.tcUrl});

      this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(privacyText);
      this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(termsText);

    });

  }

  refreshLanguage(){
    this.translate.stream("title.accept_policy",{'url': this.ppUrl}).subscribe((privacyText) => {
      this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(privacyText);
    });
    this.translate.stream("title.accept_terms",{'url': this.tcUrl}).subscribe((termsText) => {
      this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(termsText);
    });
  }

  validate() {

    if (this.country == undefined) {
      this._valid = false;
      return;
    }

    const fullPhone = `${this.country.phoneCode}${this.form.controls["phone"].value}`;
    this._valid = this.form.valid && this.country != undefined && FormUtils.validRegex(this.country.phoneValidationRegex, fullPhone);
  }

  submit() {

    if (!this._valid || !this.country || !this.tcVersion || !this.ppVersion) return;

    this.loading = true;
    const phone = this.form.controls["phone"].value;
    this.identification.userPhoneNumber = phone;

    this.signLegalTexts(() => this.saveTemporalPhone())
  }

  signLegalTexts(callback: () => void) {
    this.identification.sign(this.tcVersion!, this.ppVersion!).subscribe({
      next: _ => callback(),
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    });
  }

  saveTemporalPhone() {
    const phone = this.form.controls["phone"].value;
    this.session.phone = phone;
    this.session.country = this.country!;
    this.identification.saveTemporalPhone(this.country!.id, phone).subscribe({
      next: data => this.checkResponse(data),
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    });

    this.loading = false;
  }

  checkResponse(temporalPhoneResponse: TemporalPhone | undefined){
    
    if (temporalPhoneResponse?.userRegistered) {
      this.router.navigate([Constants.Path.FINALIZE_APP]);
    }
    else {
      this.router.navigate([Constants.Path.VERIFY_PHONE]);
    }
  }

  clearPhone() {
    this.form.controls["phone"].setValue("");
  }

  select(country: Country) {
    this.country = country;
  }

  toggle() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = "countries-dialog";
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    if (this.country) dialogConfig.data = { selected: this.country.id };

    const dialogRef = this.dialog.open(CountryDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.country = result;
    });

  }

  loadSessionLanguage() {
    this.session.$userLanguage.subscribe(language=>{
      this.http.get('assets/i18n/locale.properties', { responseType: 'text' }).subscribe({
        next:(data: string) => {
          this.datos = this.parseProperties(data);
          this.languageCode = this.obtenerClave(language) || 'es-ES';
          this.translate.use(this.languageCode);
        },
        error:(error) => {
          console.error('Error al cargar las propiedades', error);
        }
      });
    });
  }

    private obtenerClave(valor: string): string | undefined {
      return Object.keys(this.datos).find(clave => this.datos[clave] === valor.toUpperCase());
    }

    private parseProperties(data: string): { [key: string]: string } {
      return data
        .split('\n')
        .filter(line => line.trim() !== '')
        .reduce((acc, line) => {
          const [clave, valor] = line.split('=');
          if (clave && valor) {
            acc[clave.trim()] = valor.trim();
          }
          return acc;
        }, {} as { [key: string]: string });
    }
}

